import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome'

const Navigation = ({isDesktop, navOpen, closeNav, location}) => {
	const data = useStaticQuery(graphql`
		query {
		  allSanityPage {
    		edges {
					node {
						id
						order
						pageName
						_type
						slug {
							_key
							_type
							current
						}
      	}
    	}
  	}
		}
	`)

	const isCurrent = (slug) => {
		return location && location.pathname && (slug.current === location.pathname.replace('/', ''))
		? 'text-gray-600'
		: ''
	}

	let navigation 

	const pages = data.allSanityPage.edges.sort((a, b) => a.node.order - b.node.order);

	if (isDesktop) {
			navigation = <ul className="flex justify-center mt-20 mb-3">
				{pages.map(({node}) => 
					<li 
						className={"px-4 hover:text-gray-600 cursor-pointer uppercase font-bold text-sm text-center"}
						key={node.id}>
						<Link to={`/${node.slug.current}`}>
							{node.pageName}
						</Link>
						{isCurrent(node.slug) &&
							<span className="block h-1 bg-black"></span>
						}
					</li>
				)}
			</ul>
	} else {
			navigation = <div
				className={"fixed border border-r-1 border-gray-200 w-48 h-screen bg-white top-0 left-0 z-40 transition duration-300 ease-in transform -translate-x-full" + (navOpen ? 'translate-x-0' : '')}>
				<span
					className="block mt-4 cursor-pointer text-right pr-8"
					onClick={closeNav}
				><FontAwesomeIcon icon="times" /
				></span>
			<ul className="w-full flex flex-col justify-center items-center mt-28">
				{data.allSanityPage.edges.map(({node}) => 
					<li 
						className={"px-4 cursor-pointer uppercase font-bold text-sm text-center py-4" + `${isCurrent(node.slug)}`}
						key={node.id}>
						<Link to={`/${node.slug.current}`}>
							{node.pageName}
						</Link>
					</li>
				)}
			</ul>
		</div>
	}

	return (
		<nav>
			{navigation}
		</nav>
	)

}

export default Navigation
