import React, { useState, useEffect } from 'react'
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import Navigation from './navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/fontawesome'
import Logo from "../images/online_conductors.png"

const Header = ({ location }) => {
	const [navOpen, setNavOpen] = useState(false)
	const [isDesktop, setIsDesktop] = useState(false)

	useEffect(() => {
		if (typeof window !== undefined) {
			if (window.innerWidth > 1024) {
				setIsDesktop(true)
			}
			window.addEventListener("resize", () => {
				if (window.innerWidth > 1024) {
					setIsDesktop(true)
				} else {
					setIsDesktop(false)
				}
			})
			return () => {
				window.removeEventListener("resize", () => {})
			}
		}
	}, []);

	const toHomePage = () =>  {
		navigate('/')
	}

	return (
		<header className="flex flex-col items-center">
			<div className="flex items-center flex-wrap justify-center">
				<img src={Logo} alt="online conducting" className="w-64 sm:w-40 mr-3" onClick={() => toHomePage()}/>
				<h1 className="hidden sm:block md:pt-10 text-4xl sm:text-4xl md:text-5xl mb-10 mt-12 lg:mb-0 lg:mt-0 text-center px-2">
					<Link to='/'>
						Online Conductors
					</Link>
				</h1>
			</div>
			{!isDesktop && 
				<div
					className="fixed z-30 top-0 left-0 rounded-full h-10 w-10 flex items-center justify-center bg-black cursor-pointer mt-2 ml-2 border border-black"
					onClick={() => setNavOpen(true)}
				><FontAwesomeIcon icon="bars" color="white" /></div>
			}
			<Navigation 
				isDesktop={isDesktop} 
				navOpen={navOpen}
				closeNav={() => setNavOpen(false)}
				location={location}
				/>
		</header>
	)
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
